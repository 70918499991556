import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { TrialForm } from "../components/TrialForm"
import SecondPageHeader from "../container/secondPageHaaderContainer"

const TrialPage = () => (
  <Layout>
    <Seo title="無料トライアルお申し込み | 顔を出さないで感情の「見える化」をカンタンに。オンライン会議サポートツール | ワカチエ" />
    <SecondPageHeader englishTitle="- TRIAL -" mainTitle="無料トライアルお申し込み" isShowTrialLink={false} />
    <div className="TrialForm secondPageContens">
      <TrialForm/>
    </div>
  </Layout>
)

export default TrialPage
